import React from "react";
import FbViewPixel from '../../../../../Utility/FbViewPixel';
import phone from "../../../../../assets/img/OCSA_ADV2/Phone.png";
import AnchorAdv from "../../../../UI/AnchorAdv";

const AdvHeader = () => {
    return (
        <>
            <FbViewPixel />
            <section className="main_head">
                <div className="container">
                    <div className="head_foot py-2">
                        <div className="col-md-12 col-sm-12 col-12">
                            <h1 className="text-center">
                                Línea de Subsidios:
                                <span>L-V 6am-9pm</span>
                            </h1>
                        </div>
                        <div className="row">
                            <div className="col-md-2 col-sm-2 col-2 pe-1">
                                <AnchorAdv href="#" dataId="TOP_IMAGE1" className="btn-call">
                                    <button className="btn border-right-radius">
                                        <img width="55%" src={phone} alt="phone" />
                                    </button>
                                </AnchorAdv>
                            </div>
                            <div className="col-md-10 col-sm-10 col-10 ps-0">
                                <AnchorAdv href="#" dataId="TOP_IMAGE2" className="btn-call">
                                    <button className="btn border-left-radius">
                                        <h1>LLAME AHORA</h1>
                                    </button>
                                </AnchorAdv>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default AdvHeader;