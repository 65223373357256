import { Api } from "../api/Api";
export const useCollectSSn = () => {
  const getSsnData = async (token) => {
    const response = await Api.get(
      "crm/v1/ssn-info?user_token=" + token,
      {}
    );
    return response;
  };
  const StoreSsnData = async (
    message_type,
    token,
    page_name,
    query_string,
    ssn_number
  ) => {
    const StoreResponse = await Api.post("crm/v1/ssn-store", {
      message_type,
      token,
      page_name,
      query_string,
      ssn_number
    });
    return StoreResponse;
  };
 
  return { getSsnData ,StoreSsnData};
};
