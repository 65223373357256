import { Api } from "../api/Api";

export const useSsnFollowupLoad = () => {
  const loadFollowup = async (
    message_type,
    token,
    page_name,
    query_string,
  ) => {
    const FollowupLoad = await Api.post("crm/v1/ssn-followup-vist", {
      message_type,
      token,
      page_name,
      query_string,
    });
    return FollowupLoad;
  };
  return {
    loadFollowup,
  };
};
