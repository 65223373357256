import React, { useState } from "react";
import { useEffect } from "react";
import { useCollectSSn } from "../../Hooks/useCollectSSn";
import { queryString } from "../../Utility/QueryString";
import { useSsnFollowupLoad } from "../../Hooks/useSsnFollowupLoad";
import SnnSimpleModal from "./SnnSimpleModal ";
import "../../assets/css/SSN/css/custom.scss";
import logo from "../../assets/css/SSN/img/logo-white.png";
import loader from "../../assets/css/SSN/img/loader-1.gif";
import thanku from "../../assets/css/SSN/img/tahnku-1.png";
import ssl from "../../assets/css/SSN/img/ssl-img.svg";
import lock from "../../assets/css/SSN/img/lock.png";

const Collect_ssn = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const token = urlParams.get("atp_sub2");

  const { getSsnData, StoreSsnData } = useCollectSSn();
  const { loadFollowup, isuseFollowupLoad } = useSsnFollowupLoad();
  const [currentSlide, setCurrentSlide] = useState();
  const [ssn, setSsn] = useState("");
  const [resubmitClicked, setResubmitClicked] = useState(false);
  const [crmidnumeric, setCrmIdNumeric] = useState(false);


  const handleContinue = () => {
      setCurrentSlide(2);
  };
  const resubmit = () => {
      setCurrentSlide(2);
      setResubmitClicked(true);
  };

  //setting title of website from url
//   useEffect(() => {
//     setTimeout(() => {
//         var currentUrl = window.location.href;
//         var domain = currentUrl.split('/')[2];
//         document.title = domain;
//         console.log(domain);
//     }, 1000); // Adjust the timeout duration as needed (1000 milliseconds = 1 second)
// }, []);


  useEffect(() => {
    const numericRegex = /^[0-9]+$/;

    if (numericRegex.test(token)) {
      setCrmIdNumeric(true);
      (async () => {
        const response = await getSsnData(token);
        const flpResponse = await loadFollowup(
          "ssn_followup_load",
          token,
          "collect-ssn",
          queryString
        );
  
        const ssnApiResponse = JSON.parse(response.data.response.ssn_api_response);
        if (ssnApiResponse.ssn) {
          // Check if ssn_number is not empty
          if (ssnApiResponse.ssn.trim() !== "") {
            const ssn = ssnApiResponse.ssn;
            setSsn(ssnApiResponse.ssn);
            setResubmitClicked(true);
            setCurrentSlide(3);
            setTimeout(() => {
              setCurrentSlide(4);
            }, 3000);
          } else {
            // else block for SSN number is empty..
            setCurrentSlide(1);
          }
        } else {
          // else block for SSN number is not present in the response.
          setCurrentSlide(1);
        }


      // if (response.data.response['ssn_number_exist'] === true) {
      //     setCurrentSlide(3);
      //     setTimeout(() => {
      //       setCurrentSlide(4);
      //     }, 3000);
      //   }else{
      //     setCurrentSlide(1);
      //   }
      })();
    }else{
      setCurrentSlide(1);
    }
  }, []);

  const handlesubmit = () => {
    const inputElement = document.querySelector("#ssn_number");
    const value = inputElement.value.replace(/\D/g, ""); // Remove non-numeric characters
    // const regex = /^\*{3}-\*{2}-\d{4}$/;
    const regex = /^\*{3}-\*{2}-\*\d{3}$/;


    if (regex.test(inputElement.value)) {
      setCurrentSlide(3);
      setTimeout(() => {
        setCurrentSlide(4);
      }, 3000);
      document.querySelector("#ptag").textContent =
      "";
    return false;
    } else {
      if (!/^\d{9}$/.test(value)) {
        document.querySelector("#ptag").textContent =
          "Invalid input: SSN must contain 9 digits";
        return false;
      } else {
        const formattedSSN = formatSSN(value);
        inputElement.value = formattedSSN;
        (async () => {
          const StoeSSN_Response = await StoreSsnData(
            "ssn_number_store",
            token,
            "collect-ssn",
            queryString,
            formattedSSN
          );
          document.querySelector("#ptag").textContent = "";
          setCurrentSlide(3);
          setTimeout(() => {
            setCurrentSlide(4);
          }, 3000);
        })();
      }
    }
 
  };

  const handleSSNChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    // Apply the XXX-XX-XXX format
    let formattedSSN = inputValue.slice(0, 3);
    if (inputValue.length > 3) {
      formattedSSN += "-" + inputValue.slice(3, 5);
    }
    if (inputValue.length > 5) {
      formattedSSN += "-" + inputValue.slice(5, 9);
    }
    if (resubmitClicked) {
      setSsn(""); 
      setResubmitClicked(false);
    } else {
      setSsn(formattedSSN);
    }
  };

  function formatSSN(number) {
    // Apply the XXX-XX-XXXX format
    return number.replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3");
  }

  return (
    <>
      <div className="SSN">
      <div className="main">
        <div className="popup-wrap">
          <div>
            <img width="30%" src={logo} alt="logo" />
          </div>
        </div>
        <div className="slides">
          <div className={`slide-${currentSlide}`}>
            {currentSlide === 1 && (
              <div>
                <div className="text-popup">
                  <h3>
                  Estamos obligados por ley (US Patriot Act) a solicitar su SSN para verificar su identidad.
                  </h3>
                </div>
                <div className="btn-div mt-4">
                {crmidnumeric ? <button className="next1 effect-1" onClick={handleContinue}>
                  Continuar
                </button> :
                  <p className="text-danger fs-5 fw-bold"> Invaild TLD CRM Id</p>}
                </div>
                <p className="pt-1 px-2 mt-3">
                  <img src={lock} alt="" /> Nos tomamos muy en serio su seguridad y utilizamos un sistema de encriptación de nivel bancario para proteger su información.
                </p>
              </div>
            )}
            {currentSlide === 2 && (
              <div className="text-popup">
                <h3>Número de Seguro Social</h3>
                  {resubmitClicked ? (
                    <input
                      type="tel"
                      className="text-plc"
                      placeholder="***_**_***"
                      value={`***-**-*${ssn.slice(-3)}`}
                      onChange={handleSSNChange}
                      name="ssn_number"
                      id="ssn_number"
                    />
                  ) : (
                    <input
                      type="tel"
                      className="text-plc"
                      placeholder="***_**_***"
                      value={ssn}
                      onChange={handleSSNChange}
                      name="ssn_number"
                      id="ssn_number"
                    />
                  )}
                <p id="ptag" className="error mt-2"></p>
                <p className="pt-2 mt-5">
                  <img src={lock} alt="" /> Nos tomamos muy en serio su seguridad y utilizamos un sistema de encriptación de nivel bancario para proteger su información.
                </p>
                <div className="btn-div mt-5">
                  <button className="next2 effect-2" onClick={handlesubmit}>
                  Enviar
                  </button>
                  <div className="pt-3">
                    <img width="20%" src={ssl} alt="ssl" />
                  </div>
                </div>
              </div>
            )}
            {currentSlide === 3 && (
              <div className="text-center">
                <img width="50%" src={loader} alt="loader" />
              </div>
            )}
            {currentSlide === 4 && (
              <div className="text-popup pt-3">
                <div>
                  <img width="50%" src={thanku} alt="thanku" />
                </div>
                <h2 className="text-center">Muchas Gracias</h2>
                <h6 className="mt-3">
                Ha enviado de forma segura su número de la Seguridad Social (SSN)
                </h6>
                <div className="btn-div mt-4">
                  <button className="next2 effect-3" onClick={resubmit}>
                  Volver a enviar
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Collect_ssn;
