import React from "react";
import FbViewPixel from '../../../../Utility/FbViewPixel';
import phone from "../../../../assets/img/OBSA_ADV1/Phone.png";
import AnchorAdv from "../../../UI/AnchorAdv";
import obamacare from "../../../../assets/img/OBSA_ADV1/obamacare.jpg";
import seguro from "../../../../assets/img/OBSA_ADV1/seguro.png";

const AdvHeader = () => {
    return (
        <>
            <FbViewPixel />
            <section className="main_head">
                <div className="container">
                    <div className="row head_foot py-2">
                        <div className="col-lg-3 col-6 order-lg-1 order-1">
                            <AnchorAdv href="#" dataId="TOP_IMAGE1" className="">
                                <button className="btn border-right-radius text-start" style={{ padding: "5px 0" }}>
                                    <img width="40%" src={seguro} alt="seguro" />
                                </button>
                            </AnchorAdv>
                        </div>

                        <div className="col-lg-6 col-12 order-lg-2 order-3">
                            <div className="col-md-12 col-sm-12 col-12">

                                <h1 className="text-center">
                                    Línea de Subsidios:
                                    <span>L-V 6am-9pm</span>
                                </h1>
                            </div>
                            <div className="row">
                                <div className="col-md-2 col-sm-2 col-2 pe-1">
                                    <AnchorAdv href="#" dataId="TOP_IMAGE2" className="btn-call">
                                        <button className="btn border-right-radius">
                                            <img src={phone} alt="phone" />
                                        </button>
                                    </AnchorAdv>
                                </div>
                                <div className="col-md-10 col-sm-10 col-10 ps-0">
                                    <AnchorAdv href="#" dataId="TOP_IMAGE3" className="btn-call">
                                        <button className="btn border-left-radius">
                                            <h1 className="head-text">LLAME AHORA</h1>
                                        </button>
                                    </AnchorAdv>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-6 order-lg-3 order-2">
                            <AnchorAdv href="#" dataId="TOP_IMAGE4" className="">
                                <button className="btn border-right-radius text-end" style={{ padding: "5px 0" }}>
                                    <img width="55%" src={obamacare} alt="obamacare" />
                                </button>
                            </AnchorAdv>

                        </div>

                    </div>

                </div>
            </section>
        </>
    );
};
export default AdvHeader;