// import { lazy } from 'react';
// const Split_1 =  import('./Components/Pages/Splits/Split_1');

// export {
//     Split_1
// }

import React, { lazy } from 'react';

const NotFound = lazy(() => import('./Components/Pages/NotFound'));
const AdtopiaLoadValue = lazy(() => import('./Components/Pages/AdtopiaLoadValue'));
const HomePage = lazy(() => import('./Components/Pages/HomePage'));
const OBSRO_SPH_V10 = lazy(() => import('./Components/Pages/OBSRO_SPH_V10/OBSRO_SPH_V10'))
const OBSRO_SPH_V11 = lazy(() => import('./Components/Pages/OBSRO_SPH_V11/OBSRO_SPH_V11'))


const AppSplitList = (props) => {

    const SplitListMap = {
        'NotFound': NotFound,
        'SPR_V1': HomePage,
        'AdtopiaLoadValue': AdtopiaLoadValue,
        'SPR_V6B' : HomePage,
        'OBSRO_SPH_V10':OBSRO_SPH_V10,
        'OBSRO_SPH_V11':OBSRO_SPH_V11,
       
        
    };
    const SplitComponent = SplitListMap[props.splitName];
    return (
        <>
            {SplitComponent && <SplitComponent />}
        </>
    )

}

export default AppSplitList;