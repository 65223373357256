import React from 'react'
import logo_1 from "../../../../../assets/img/OBSA_ADV2/logo-1.png"
import AnchorAdv from '../../../../UI/AnchorAdv'

const AdvHeader = () => {
  return (
    <div>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 logo">
              <AnchorAdv href="#" dataId="TOP_IMAGE1" className="">
                <img src={logo_1} alt="" />
              </AnchorAdv>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end">
              <AnchorAdv href="#" dataId="TOP_CTA_BTN" className="get_btn btn">
                Llame Ahora
              </AnchorAdv>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default AdvHeader