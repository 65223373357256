import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import { RedirectProvider } from "./Contexts/RedirectContext";
import NotFound from "./Components/Pages/NotFound";
import Privacy_policy from "./Components/Includes/Layouts/Common/Privacy_policy";
import Terms_of_use from "./Components/Includes/Layouts/Common/Terms_of_use";
import Cookies_policy from "./Components/Includes/Layouts/Common/Cookies_policy";
import Terminos_de_Uso from "./Components/Includes/Layouts/Common/Terminos_de_Uso";
import Política_de_Privacidad from "./Components/Includes/Layouts/Common/Política_de_Privacidad";
import Política_de_Cookies from "./Components/Includes/Layouts/Common/Política_de_Cookies";
import AdtopiaLoadValue from "./Components/Pages/AdtopiaLoadValue";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
import MedicareThankYou from "./Components/Pages/MedicareThankYou";
import GreencardThankyou from "./Components/Pages/GreencardThankyou";
import OBSRO_SPH_V10 from "./Components/Pages/OBSRO_SPH_V10/OBSRO_SPH_V10";
import MedicareThankYouV2 from "./Components/Pages/MedicareThankYouV2";
import Collect_ssn from "./Components/Pages/Collect_ssn";
import Collect_ssn_v2 from "./Components/Pages/Collect_ssn_v2";
import OBSRO_SPH_V11 from "./Components/Pages/OBSRO_SPH_V11/OBSRO_SPH_V11";
import OCSA_ADV1 from "./Components/Pages/Advertorials/OCSA_ADV1";
import OCSA_ADV2 from "./Components/Pages/Advertorials/OCSA_ADV2";
import OBSA_ADV1 from "./Components/Pages/Advertorials/OBSA_ADV1";
import OBSA_ADV2 from "./Components/Pages/Advertorials/OBSA_ADV2";

const App = () => {

  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  useEffect(() => {
    // document.title = EnvConstants.AppTitle;
    var currentUrl = window.location.href;
    var domain = currentUrl.split('/')[2];
    document.title = domain;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
                <Route exact path="/" component={HomePage} />
                <Route exact path={["/fbpixel"]} component={FbPixel} />
                <Route exact path={["/404"]} component={NotFound} />
                <Route exact path={["/SPR_V1"]} component={HomePage} />
                <Route exact path={["/Privacy-policy"]} component={Privacy_policy} />
                <Route exact path={["/Terms-of-use"]} component={Terms_of_use} />
                <Route exact path={["/Cookies-policy"]} component={Cookies_policy} />
                <Route exact path={["/Política-de-Privacidad"]} component={Política_de_Privacidad} />
                <Route exact path={["/Política-de-Cookies"]} component={Política_de_Cookies} />
                <Route exact path={["/Terminos-de-Uso"]} component={Terminos_de_Uso} />
                <Route exact path={["/SPR_V6B"]} component={HomePage} />
                <Route exact path={"/medicare-thankyou"} component={MedicareThankYou}/>
                <Route exact path={"/greencard-thankyou"} component={GreencardThankyou}/>
                <Route path="/value/:dynamicValue" component={AdtopiaLoadValue} />
                <Route exact path={["/google","/testing","/index"]} component={AdtopiaLoadDynamic}/>
                <Route exact path={["/OBSRO_SPH_V10"]} component={OBSRO_SPH_V10}/>
                <Route exact path={["/OBSRO_SPH_V11"]} component={OBSRO_SPH_V11}/>
                <Route exact path={["/collect-ssn"]} component={Collect_ssn}/>
                <Route exact path={["/v2/collect-ssn"]} component={Collect_ssn_v2}/>
                <Route exact path={"/medicare-thankyou-v2"} component={MedicareThankYouV2}/>
                <Route exact path={"/OCSA_ADV1"} component={OCSA_ADV1}/>
                <Route exact path={"/OCSA_ADV2"} component={OCSA_ADV2}/>
                <Route exact path={"/OBSA_ADV1"} component={OBSA_ADV1}/>
                <Route exact path={"/OBSA_ADV2"} component={OBSA_ADV2}/>
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;