import React from 'react'
import AdtopiaLoadAP from '../../../Utility/AdtopiaLoadAP'
import AdvHeader from '../../Includes/Layouts/Advertorials/OBSA_ADV2/AdvHeader'
import FbViewPixel from '../../../Utility/FbViewPixel'
import Footer from "../../Includes/Layouts/SPR_V9/Footer";
import AnchorAdv from '../../UI/AnchorAdv';
import "../../../assets/css/OBSA_ADV2/custom.scss"
import Paraimg3 from "../../../assets/img/OBSA_ADV2/Paraimg3.jpg"

const OBSA_ADV2 = () => {
  const pageName = "OBSA_ADV2"

  let num1 = [];
  let num2 = [];
  for (let i = 25, j = 1; i <= 60; i++, j++) {
    let buttonText = i;
    if (i === 25) {
      buttonText = "-" + i;
    } else if (i === 60) {
      buttonText = i + "+";
    }
    num1.push(
      <AnchorAdv key={j} href="#" className="banner-btn-color1" dataId={"PARA_NUM" + j}>
        {buttonText}
      </AnchorAdv>
    )
    num2.push(
      <AnchorAdv key={j} href="#" className="banner-btn-color1" dataId={"MID_PARA_NUM" + j}>
        {buttonText}
      </AnchorAdv>
    )
  }
  return (
    <>
      <AdtopiaLoadAP pageType="AP" splitName={pageName} />
      <FbViewPixel />
      <div className="OBSA_ADV2">
        <div className="seguro_am_main">
          <AdvHeader />
          <section className="seg_sec_dec">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 text-center">
                  <h1>Aquí te mostramos cómo obtener $1,000/mes</h1>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-12 col-12">

                  <AnchorAdv href="#" className="" dataId="PARA_IMAGE_1" >
                    <img src={Paraimg3} alt="" className="banner" />
                  </AnchorAdv>
                  <div className="elementory_sec py-3 container">
                    <div className="row">
                      {/* <div className="col-12 text-center">
                        <h5>
                          <b>Aquí te mostramos cómo obtener...</b>
                        </h5>
                        <div className="col-7 m-auto">
                          <h5 className="dlr">$1000/mes</h5>
                        </div>
                      </div> */}
                      <div className="col-12">
                        <p>
                          ¿No tienes seguro de salud porque es demasiado caro y
                          necesitas ese dinero para cosas importantes como alimentos y
                          alquiler?
                        </p>
                        <h5 className="resuelto">
                          <b>Problema Resuelto</b>
                        </h5>
                      </div>
                      <div className="col-12">
                        <p>
                          Millones de latinos califican para un plan de $0/mes,{" "}

                          <AnchorAdv href="#" className="age-btn" dataId="MID_PARA1" >
                            <b>
                              <u>¡solo necesitan llamar a este número para calificar!</u>
                            </b>
                          </AnchorAdv>{" "}
                          Únete a los 35 millones de personas que están reclamando este
                          beneficio en 2024.{" "}
                        </p>
                      </div>
                      <div className="cta_sec_dsk">
                        <div className="row">
                          <div className="col-lg-12 col-xs-12 banner text-center">
                            <h2>Selecciona to edad</h2>
                            {num1}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 Cómo mt-4">
                        <h5>
                          <b>Cómo Funciona el Programa del Gobierno 'Obamacare':</b>
                        </h5>
                        <p>
                          1.{" "}
                          <AnchorAdv href="#" className="age-btn" dataId="MID_PARA2" >
                            <b>
                              <u>Llama a ESTE número</u>
                            </b>
                          </AnchorAdv>{" "}
                          para hablar con un especialista que te calificará{" "}
                        </p>
                        <p>
                          2. El especialista te inscribirá con un proveedor de seguro de
                          salud
                        </p>
                        <p>
                          3. El Gobierno 'subsidia' tu costo. (Ellos pagan a tu compañía
                          de seguros por ti)
                        </p>
                        <p>
                          4. Usa el dinero que ahorras en cosas importantes como las facturas del hogar, el entretenimiento, los viajes y el ocio!
                        </p>
                        <p>
                          <b>
                            Este programa está{" "}
                            <span>
                              financiado con tus dólares ganados con esfuerzo.
                            </span>{" "}
                            ¡No cometas el error de perder este nuevo programa de
                            estímulo para ti y tu familia!{" "}
                          </b>
                        </p>
                      </div>
                      <div className="cta_sec_dsk">
                        <div className="row">
                          <div className="col-lg-12 col-xs-12 banner text-center">
                            <h2>Selecciona to edad</h2>
                            {num2}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <AnchorAdv href="#" dataId="PARA_IMAGE_1" className="org_btn btn">
                      LLAME AHORA
                    </AnchorAdv>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default OBSA_ADV2