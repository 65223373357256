import React, { useState } from "react";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import AdvHeader from "../../Includes/Layouts/OBSA_ADV1/AdvHeader";
import "../../../../src/assets/css/OBSA_ADV1/OBSA_ADV1.scss";
import AdvFooter from "../../Includes/Layouts/OBSA_ADV1/AdvFooter";
import AnchorAdv from "../../UI/AnchorAdv";
import Footer from "../../Includes/Layouts/OBSA_ADV1/Footer";


const OBSA_ADV1 = () => {

    return (
        <>
            <AdtopiaLoadAP pageType="AP" splitName="OBSA_ADV1" />
            <div className="OBSA_ADV1">
                <AdvHeader />
                <section className="paralatinos">
                    <div className="elementory_sec container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h5>
                                    <b>Aquí te mostramos cómo obtener...</b>
                                </h5>
                                <div className="col-7 m-auto">
                                    <h5 className="dlr text-style">
                                    $1000/mes
                                    </h5>
                                </div>
                            </div>
                            <div className="col-12">
                                <p>
                                    ¿No tienes seguro de salud porque es demasiado caro y necesitas ese dinero para cosas importantes como alimentos y alquiler?
                                </p>
                                <h5 className="resuelto">
                                    <b className="text-style">Problema Resuelto</b>
                                </h5>
                            </div>
                            <div className="col-12">
                                <p>
                                    Millones de latinos califican para un plan de $0/mes, <AnchorAdv href="#" className="age-btn" dataId="MID_PARA1" ><b><u>¡solo necesitan llamar a este número para calificar!</u></b></AnchorAdv> Únete a los 35 millones de personas  que están reclamando este beneficio en 2024.
                                </p>
                            </div>
                            <div className="col-12 btn-div">
                                <h5 className="text-center py-2">
                                    <b className="select-yearstyle">Selecciona Tu Edad:</b>
                                </h5>
                                <div className="row px-2 pb-2">
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM1" >
                                            <button className="br-50">
                                                -25
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM2" >
                                            <button className="br-50">
                                                26
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM3" >
                                            <button className="br-50">
                                                27
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM4" >
                                            <button className="br-50">
                                                28
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM5" >
                                            <button className="br-50">
                                                29
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM6" >
                                            <button className="br-50">
                                                30
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM7" >
                                            <button className="br-50">
                                                31
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM8" >
                                            <button className="br-50">
                                                32
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM9" >
                                            <button className="br-50">
                                                33
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM10" >
                                            <button className="br-50">
                                                34
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM11" >
                                            <button className="br-50">
                                                35
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM12" >
                                            <button className="br-50">
                                                36
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM13" >
                                            <button className="br-50">
                                                37
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM14" >
                                            <button className="br-50">
                                                38
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM15" >
                                            <button className="br-50">
                                                39
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM16" >
                                            <button className="br-50">
                                                40
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM17" >
                                            <button className="br-50">
                                                41
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM18" >
                                            <button className="br-50">
                                                42
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM19" >
                                            <button className="br-50">
                                                43
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM20" >
                                            <button className="br-50">
                                                44
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM21" >
                                            <button className="br-50">
                                                45
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM22" >
                                            <button className="br-50">
                                                46
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM23" >
                                            <button className="br-50">
                                                47
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM24" >
                                            <button className="br-50">
                                                48
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM25" >
                                            <button className="br-50">
                                                49
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM26" >
                                            <button className="br-50">
                                                50
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM27" >
                                            <button className="br-50">
                                                51
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM28" >
                                            <button className="br-50">
                                                52
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM29" >
                                            <button className="br-50">
                                                53
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM30" >
                                            <button className="br-50">
                                                54
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM31" >
                                            <button className="br-50">
                                                55
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM32" >
                                            <button className="br-50">
                                                56
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM33" >
                                            <button className="br-50">
                                                57
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM34" >
                                            <button className="br-50">
                                                58
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM35" >
                                            <button className="br-50">
                                                59
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="PARA_NUM36" >
                                            <button className="br-50">
                                                60+
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 Cómo mt-4">
                                <h5>
                                    <b>Cómo Funciona el Programa del Gobierno 'Obamacare':</b>
                                </h5>
                                <p>
                                    1. <AnchorAdv href="#" className="age-btn" dataId="MID_PARA2" ><b><u>Llama a ESTE número</u></b></AnchorAdv> para hablar con un especialista que te calificará
                                </p>
                                <p>
                                    2. El especialista te inscribirá con un proveedor de seguro de salud
                                </p>
                                <p>
                                    3. El Gobierno 'subsidia' tu costo. (Ellos pagan a tu compañía de seguros por ti)
                                </p>
                                <p>
                                    4. Usa el dinero que ahorras en cosas importantes como las facturas del hogar, el entretenimiento, los viajes y el ocio!
                                </p>
                                <p>
                                    <b>
                                        Este programa está <span>financiado con tus dólares ganados con esfuerzo.</span> ¡No cometas el error de perder este nuevo programa de estímulo para ti y tu familia!
                                    </b>
                                </p>
                            </div>
                            <div className="col-12 btn-div">
                                <h5 className="text-center py-2">
                                    <b className="select-yearstyle">Selecciona Tu Edad:</b>
                                </h5>
                                <div className="row px-2 pb-2">
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM1" >
                                            <button className="br-50">
                                                -25
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM2" >
                                            <button className="br-50">
                                                26
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM3" >
                                            <button className="br-50">
                                                27
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM4" >
                                            <button className="br-50">
                                                28
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM5" >
                                            <button className="br-50">
                                                29
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM6" >
                                            <button className="br-50">
                                                30
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM7" >
                                            <button className="br-50">
                                                31
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM8" >
                                            <button className="br-50">
                                                32
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM9" >
                                            <button className="br-50">
                                                33
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM10" >
                                            <button className="br-50">
                                                34
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM11" >
                                            <button className="br-50">
                                                35
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM12" >
                                            <button className="br-50">
                                                36
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM13" >
                                            <button className="br-50">
                                                37
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM14" >
                                            <button className="br-50">
                                                38
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM15" >
                                            <button className="br-50">
                                                39
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM16" >
                                            <button className="br-50">
                                                40
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM17" >
                                            <button className="br-50">
                                                41
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM18" >
                                            <button className="br-50">
                                                42
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM19" >
                                            <button className="br-50">
                                                43
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM20" >
                                            <button className="br-50">
                                                44
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM21" >
                                            <button className="br-50">
                                                45
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM22" >
                                            <button className="br-50">
                                                46
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM23" >
                                            <button className="br-50">
                                                47
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM24" >
                                            <button className="br-50">
                                                48
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM25" >
                                            <button className="br-50">
                                                49
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM26" >
                                            <button className="br-50">
                                                50
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM27" >
                                            <button className="br-50">
                                                51
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM28" >
                                            <button className="br-50">
                                                52
                                            </button>
                                        </AnchorAdv>
                                    </div>

                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM29" >
                                            <button className="br-50">
                                                53
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM30" >
                                            <button className="br-50">
                                                54
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM31" >
                                            <button className="br-50">
                                                55
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM32" >
                                            <button className="br-50">
                                                56
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM33" >
                                            <button className="br-50">
                                                57
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM34" >
                                            <button className="br-50">
                                                58
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM35" >
                                            <button className="br-50">
                                                59
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                    <div className="col-3 mb-3">
                                        <AnchorAdv href="#" className="age-btn" dataId="MID_PARA_NUM36" >
                                            <button className="br-50">
                                                60+
                                            </button>
                                        </AnchorAdv>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <AdvFooter />
                <Footer/>
            </div>

        </>
    )

};

export default OBSA_ADV1;