import React, { useState } from "react";
import { useEffect } from "react";
import { useCollectSSn } from "../../Hooks/useCollectSSn";
import { queryString } from "../../Utility/QueryString";
import { useSsnFollowupLoad } from "../../Hooks/useSsnFollowupLoad";
import SnnSimpleModal from "./SnnSimpleModal ";
import "../../assets/css/SSN_V2/css/custom.scss";
import loader from "../../assets/css/SSN_V2/img/loader.gif";
import thanku from "../../assets/css/SSN_V2/img/thanku.png";

const Collect_ssn_v2 = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const token = urlParams.get("atp_sub2");

  const { getSsnData, StoreSsnData } = useCollectSSn();
  const { loadFollowup, isuseFollowupLoad } = useSsnFollowupLoad();
  const [currentSlide, setCurrentSlide] = useState();
  const [ssn, setSsn] = useState("");
  const [resubmitClicked, setResubmitClicked] = useState(false);
  const [crmidnumeric, setCrmIdNumeric] = useState(false);

  const handleContinue = () => {
      setCurrentSlide(2);
  };
  const resubmit = () => {
    setCurrentSlide(2);
    setResubmitClicked(true);
};

  useEffect(() => {
    const numericRegex = /^[0-9]+$/;

    if (numericRegex.test(token)) {
      setCrmIdNumeric(true);
      (async () => {
        const response = await getSsnData(token);
        const flpResponse = await loadFollowup(
          "ssn_followup_load",
          token,
          "v2/collect_ssn",
          queryString
        );
        const ssnApiResponse = JSON.parse(response.data.response.ssn_api_response);
        if (ssnApiResponse.ssn) {
          // Check if ssn_number is not empty
          if (ssnApiResponse.ssn.trim() !== "") {
            const ssn = ssnApiResponse.ssn;
            setSsn(ssnApiResponse.ssn);
            setResubmitClicked(true);
            setCurrentSlide(3);
            setTimeout(() => {
              setCurrentSlide(4);
            }, 3000);
          } else {
            // else block for SSN number is empty..
            setCurrentSlide(1);
          }
        } else {
          // else block for SSN number is not present in the response.
          setCurrentSlide(1);
        }

        // if (response.data.response['ssn_number_exist'] === true) {
        //   setCurrentSlide(3);
        //   setTimeout(() => {
        //     setCurrentSlide(4);
        //   }, 3000);
        // }else{
        //   setCurrentSlide(1);
        // }
      })();
    }else{
      setCurrentSlide(1);
    }
  }, []);

  const handlesubmit = () => {
    const inputElement = document.querySelector("#ssn_number");
    const value = inputElement.value.replace(/\D/g, ""); // Remove non-numeric characters
    // const regex = /^\*{3}_\*{2}_\d{4}$/;
    const regex = /^\*{3}_\*{2}_\*\d{3}$/;

    if (regex.test(inputElement.value)) {
      setCurrentSlide(3);
      setTimeout(() => {
        setCurrentSlide(4);
      }, 3000);
      document.querySelector("#ptag").textContent =
      "";
    return false;
    }else{

      if (!/^\d{9}$/.test(value)) {
        document.querySelector("#ptag").textContent ="Invalid input: SSN must contain 9 digits";
        return false;
      } else {
        const formattedSSN = formatSSN(value);
        inputElement.value = formattedSSN;
        (async () => {
          const StoeSSN_Response = await StoreSsnData(
            "ssn_number_store",
            token,
            "v2/collect_ssn",
            queryString,
            formattedSSN
          );
          document.querySelector("#ptag").textContent = "";
            setCurrentSlide(3);
            setTimeout(() => {
              setCurrentSlide(4);
            }, 3000);
        })();
      }
    }
  };

  const handleSSNChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, "");

    // Apply the XXX-XX-XXX format
    let formattedSSN = inputValue.slice(0, 3);
    if (inputValue.length > 3) {
      formattedSSN += "-" + inputValue.slice(3, 5);
    }
    if (inputValue.length > 5) {
      formattedSSN += "-" + inputValue.slice(5, 9);
    }
   
    if (resubmitClicked) {
        setSsn(""); 
        setResubmitClicked(false);
      } else {
        setSsn(formattedSSN);
      }
    
  };

  function formatSSN(number) {
    // Apply the XXX-XX-XXXX format
    return number.replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3");
  }

  return (
    <>
    <div className="SSN_V2">
  <div className="main">
     
     <div className="slides">
       <div className={`slide-${currentSlide}`}>
       {currentSlide === 1 && (
        <>
         <div className="text-popup">
           <h3>
            Estamos obligados por ley (US Patriot Act) a solicitar su SSN para verificar su identidad.
           </h3>
           <p className="pt-2 fs-18">
           Nos tomamos muy en serio su seguridad y utilizamos un sistema de encriptación de nivel bancario para proteger su información.
           </p>
         </div>
         <div className="btn-div">
          {crmidnumeric ? <button className="next1 effect-1" onClick={handleContinue}>
            Continuar
          </button> :
            <p className="text-danger fs-5 fw-bold"> Invaild TLD CRM Id</p>}
         </div>
         </>
          )}
       </div>
       
       {/* <div className="slide-2 pt-3" style={{display: "none"}}> */}
       {currentSlide === 2 && (
        <>
         <div className="text-popup">
           <h3>Número de Seguro Social</h3>
           {resubmitClicked ? ( 
             <input
               type="tel"
               className="text-plc"
               placeholder="***_**_***"
               value={`***_**_*${ssn.slice(-3)}`} 
               onChange={handleSSNChange}
               name="ssn_number"
               id="ssn_number"
             />
           ) : (
             <input
               type="tel"
               className="text-plc"
               placeholder="***_**_***"
               value={ssn}
               onChange={handleSSNChange}
               name="ssn_number"
               id="ssn_number"
             />
           )}
             <p id="ptag" className="error" style={{ color: 'red' }}></p>
           <p className="mb-0 pt-3 fs-14">
           Nos tomamos muy en serio su seguridad y utilizamos un sistema de encriptación de nivel bancario para proteger su información.
           </p>
         </div>
         <div className="btn-div mt-3">
           <button className="next2 effect-2" onClick={handlesubmit}>
           Enviar
           </button>
         </div>
         </>
          )}
     {/* </div> */}
       {/* <div className="slide-3 pt-5 mt-5" style={{display: "none"}}> */}
       {currentSlide === 3 && (     
         <div className="text-center">
           <img  width="50%" src={loader} alt="loader"/>
         </div>
           )}
       {/* </div> */}
       {/* <div className="slide-4 pt-5 mt-5" > */}
       {currentSlide === 4 && (
        <>
         <div className="text-popup pt-3">
           <div>
             <img width="50%" src={thanku} alt="thanku"/>
           </div>
           <h2 className="text-center">Muchas Gracias</h2>

           <br/>
           
           <h6>Ha enviado de forma segura su número de la Seguridad Social (SSN)</h6>
           <div className="btn-div">
                  <button className="next2 effect-2" onClick={resubmit}>
                  Volver a enviar
                  </button>
                </div>
         </div>
         </>
          )}
       {/* </div> */}
     </div>
     <div className="popup-wrap">
       <div>
       </div>
     </div>
   </div>
   </div>
    </>
      
  );
};

export default Collect_ssn_v2;
